/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me1.png`} alt="" />
      </Link>
      <header>
        <h2>Clément Creusot</h2>
        <p><a href="mailto:clement@creusot.pro">clement@creusot.pro</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, my name is Clément, i&apos;m French, IT architect and Digital Nomad.
        I studied at school <a href="https://42lyon.fr">42 Lyon</a>.
        I worked 2 years at <a href="https://rebrain.ai">Rebrain.ai</a> (specialized in automated market making on crypto markets).
        Now i&apos;m self-employed and creating trading algorithms on Crypto and NFT markets.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/about') ? <Link to="/about" className="button">About Me</Link> : <Link to="/resume" className="button">Resume</Link> }
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Clément Creusot <Link to="/about">creusot.pro</Link>.</p>
    </section>
  </section>
);

export default SideBar;
